<template>
    <div class="banner_section" v-if="sliderData">			
        <div class="home_banner_section">
            <div class="home_slider">
                <div class="home-banner">					
					<carousel v-if="loaded" :autoplay="autoplay" :nav="nav" :number="number" :responsive="responsive">
						<div class="item" v-for="(itm, index) of sliderData" :key="index">
							<div class="slide_box">
								<img
									data-sizes="auto"
									:data-src="dirPath.large+'/'+itm.image_name"
									:data-srcset="dirPath.large+'/'+itm.image_name +' 300w, '+
									dirPath.large+'/'+itm.image_name +' 600w, '+
									dirPath.large+'/'+itm.image_name +' 900w'" 
									class="lazyload" :alt="itm.heading" 
								/>
							</div>
							
							<div class="slide_textwrap" v-if="itm.heading || itm.content || itm.bannerLink">
								<div class="container">
									<div class="row justify-content-center">
										<div class="col-md-8">
											<div class="slide_text">   
												<h2 v-if="itm.heading">{{ itm.heading }}</h2>
												<div v-if="itm.content" v-html="itm.content"></div>
												<a v-if="itm.bannerLink" :href="itm.bannerLink">{{ buttonContent('click_here') }}</a>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>						
					</carousel>
				</div>
            </div>
        </div>
    </div>
</template>

<script>
import carousel from 'vue-owl-carousel'
import Translation from '../../public/translation.json'

export default {
  	components: { carousel },
    data() {
        return {
            defaultLang: localStorage.getItem('_store_lang') || 'en', 
			number: 1,
            autoplay: true,
            nav: false,
			autoplayTimeout: 5000,
			animateIn: 'fadeIn', // add this
  			animateOut: 'fadeOut', // and this
			responsive: {0:{items:1,nav:false},600:{items:1,nav:false},1500:{items:1}},
			sliderData: [],
			dirPath: '',
			loaded: false,
        }
	},
	methods: {
		buttonContent(field){
            return Translation.button[this.defaultLang][field]
        },
		getSlider(){
			this.$http.get('/banner').then(res=>{
				if(res.data.status === true){
					this.sliderData = res.data.data
					if(res.data.path)
						this.dirPath = res.data.path
					this.loaded = true
				}
			})
			.catch(err=>{
				console.log(err.response)
			})
		}
	},
	created: function(){
		this.getSlider()
	}
}
</script> 

<style scoped>
.banner_section {
	position: relative;
}
.home_banner_section {
	position: relative;
}
.home_slider .owl-carousel .owl-nav .owl-next,
.home_slider .owl-carousel .owl-nav .owl-prev {
	width: 52px;
	height: 52px;
	line-height: 52px;
	border: 0;
	background: #43baff;
	opacity: 0;
	visibility: hidden;
	transition: all 500ms ease 0s;
}
.home_slider .owl-carousel .owl-nav .owl-next:hover,
.home_slider .owl-carousel .owl-nav .owl-prev:hover {
	background: #43baff;
}
.home_slider .owl-carousel .owl-nav .owl-prev {
	left: -50px;
}
.home_slider .owl-carousel .owl-nav .owl-next {
	right: -50px;
}
.home_slider:hover .owl-carousel .owl-nav .owl-prev {
	left: 50px;
	opacity: 1;
	visibility: visible;
}
.home_slider:hover .owl-carousel .owl-nav .owl-next {
	right: 50px;
	opacity: 1;
	visibility: visible;
}
.home_slider .owl-carousel .owl-nav .owl-next i,
.home_slider .owl-carousel .owl-nav .owl-prev i {
	font-size: 0;
	height: auto;
}
.home_slider .owl-carousel .owl-nav .owl-prev i {
	border-right: 9px solid #fff;
	border-top: 6px solid transparent;
	border-bottom: 6px solid transparent;
}
.home_slider .owl-carousel .owl-nav .owl-next i {
	border-left: 9px solid #fff;
	border-top: 6px solid transparent;
	border-bottom: 6px solid transparent;
}
.home_slider .owl-carousel .owl-nav .owl-next i:before,
.home_slider .owl-carousel .owl-nav .owl-prev i:before {
	display: none;
}
.slide_box a {
	display: block;
}
.slide_box {
	position: relative;
}
.slide_box img {
	width: 100%;
	height: auto;
}
</style>