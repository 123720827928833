<template>
    <fragment>
        <HeroSlider/> 
        <Search/> 
        <About/>
        <Category/>   
        <Slider/>   
        <!-- <Courses/>    -->
        <Counter/> 
        <Brand />
    </fragment>
</template>

<script>
import HeroSlider from "@/components/HeroSlider.vue";
import Search from "@/components/Search.vue";
import Category from "@/components/Category.vue";
import About from "@/components/About.vue";
import Slider from "@/components/Slider.vue";
import Courses from "@/components/Courses.vue";
import Counter from "@/components/Counter.vue";
import Brand from "@/components/Brand.vue";

export default {
    name: "App",   
    components: {
        HeroSlider, Search, Category, About, Slider, Courses, Counter, Brand
    }
};
</script>


