<template>
    <div class="search_section">
        <div class="container">
            <div class="srch_section">
                <form class="search-form" action="/courses">
                    <div id="selparent" class="nice-select" @click="selFunction">
                        <div class="selvalue" id="selectedvalue">{{ pageContent('all_categories') }}</div>
                        <ul class="list">
                            <li data-value="all" class="option selected">{{ pageContent('all_categories') }}</li>
                            <li 
                                :data-value="itm.id" 
                                class="option" 
                                v-for="(itm, index) of categories" 
                                :key="index"
                                @click="selectValue(itm.id, itm.title)"
                            >{{ itm.title }}</li>
                        </ul>
                    </div>
                    <input type="text" :placeholder="placeholderText('enter_course_name')" name="srch" required>
                    <input type="hidden" name="category" value="all">
                    <button type="submit"><i class="icon-material-outline-search"></i></button>
                </form>
            </div>    
        </div>
    </div>
</template>

<script>
import Translation from '../../public/translation.json'
export default {
    data(){
        return {
            categories: [],
            defaultLang: localStorage.getItem('_store_lang') || 'en'
        }
    },
    methods: { 
        placeholderText(field){
            return Translation.placeholder[this.defaultLang][field]
        },
        pageContent(field) {
            return Translation.content[this.defaultLang][field]
        },
        selFunction() {
            document.getElementById("selparent").classList.toggle("open");
        },
        selectValue(key, title) {
            document.querySelector('input[name="category"]').value = key
            document.getElementById("selectedvalue").textContent = title
            document.querySelectorAll('ul.list li').forEach(item => {
                item.classList.remove('selected')
            })
            document.querySelector('li[data-value="'+ key +'"').classList.add('selected')
        },
        courseCategories(){
            let dispatch = 'courseCategories'
            // if(this.$store.getters.storeToken)
            //     dispatch = 'userCourseCategories';

            this.$store.dispatch(dispatch).then(res=>{
                if(res.data.status === true)
                    this.categories = res.data.data
            })
            .catch(error => console.log('Error:' +error.response.data.error))
        }
    },
    created: function(){
        this.courseCategories()
    }
}
</script>

