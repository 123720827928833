<template>
    <div class="section layout_padding padding_bottom-0 section-light popularcourse" v-if="courseData.length > 0">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <div class="full">
                        <div class="heading_main text_align_center">
                            <h2 v-html="pageTitle[0].title"></h2>
                        </div>
                    </div>
                </div>
            </div>
            <div class="carousel_wrap wow fadeInUp">
                <div class="course-slider"> 
                    <carousel v-if="loaded" :autoplay="autoplay" :nav="nav" :number="5" :margin="margin" :responsive="responsive">
                        <CourseLists :courses="courseData" />
                    </carousel>
                </div>
            </div>	  
        </div>
    </div> 
</template>

<script>
import carousel from 'vue-owl-carousel'
import CourseLists from './Course/CourseLists'
import Translation from '../../public/translation.json'

export default {
    components: { carousel, CourseLists },
    data() {
        return {
            number: 5,
            autoplay: false,
            nav: false,
            margin: 15,
            responsive: {0:{items:1,nav:false},600:{items:1,nav:true},641:{items:2,nav:true},1024:{items:3,nav:true},1500:{items:4}},
            courseData: [],
            loaded: false,
            heading: [
                {lang: 'en', 'title': `<span>Courses/Activities</span> In Demand`},
                {lang: 'ar', 'title': `الموادّ والأنشطة المطلوبة`},
                {lang: 'fr', 'title': `<span>Cours & Activités </span>Recherchés`}
            ],
            defaultLang: localStorage.getItem('_store_lang') || 'en'  
        }
    },
    computed: {
        pageTitle() {
            return this.heading.filter(item=> {
                return (item.lang === this.defaultLang) ? item : ''
            })
        }
    },
    methods: {
        pageContent(field) {
            return Translation.content[this.defaultLang][field]
        },
        getCourses() {
            let dispatch = 'getCourses'
            let params = {};
            // if(this.$store.getters.storeToken)
            //     dispatch = 'userCourses';

            params.clause = {
                popular_course: 'Y'
            }
          
            this.$store.dispatch(dispatch, {params}).then(res=>{
                if(res.data.status === true) {
                    this.courseData = res.data.data;
                    this.loaded = true
                }
            })
            .catch(err=>{
                console.log(err.response.data.error)
            })
        }
    },    
    created: function(){
        this.getCourses()
    }
}
</script>