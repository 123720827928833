<template>
    <section class="section statistics-section">
        <div class="container">
            <div class="row" id="counter">
                <div class="col-12 col-md-4 counter-Txt">
                    <div class="statistics-list text-center">
                        <span class="counter-value" :data-target="happy_students">0</span>
                        <h3 v-html="studentsTitle[0].title"></h3>
                    </div>
                </div>
                <div class="col-12 col-md-4 counter-Txt">
                    <div class="statistics-list text-center">
                        <span class="counter-value" :data-target="online_courses">0</span>
                        <h3 v-html="courseTitle[0].title"></h3>
                    </div>
                </div>
                <div class="col-12 col-md-4">
                    <div class="statistics-list text-center">
                        <span class="counter-value" :data-target="total_professor">0</span>
                        <h3 v-html="professorsTitle[0].title"></h3>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    data() {
        return {
            speed: 50,
            defaultLang: localStorage.getItem('_store_lang') || 'en',
            heading: [
                {
                    students: [
                        {lang: 'en', 'title': `Happy Members`},
                        {lang: 'ar', 'title': `طلاب سعداء`},
                        {lang: 'fr', 'title': `DES MEMBRES HEUREUX`}
                    ],
                    courses: [
                        {lang: 'en', 'title': `Online Courses/Activities`},
                        {lang: 'ar', 'title': `مواد وأنشطة مباشرة على الانترنت`},
                        {lang: 'fr', 'title': `COURS/ACTIVITÉS EN LIGNE`}
                    ],
                    professors: [
                        {lang: 'en', 'title': `Instructors`},
                        {lang: 'ar', 'title': `أساتذة/مدرّبين`},
                        {lang: 'fr', 'title': `INSTRUCTEURS/INSTRUCTRICES`}
                    ]
                }
            ],
        }
    },
    computed: {
        studentsTitle() {
            return this.heading[0].students.filter(item=> {
                return (item.lang === this.defaultLang) ? item : ''
            })
        },
        courseTitle() {
            return this.heading[0].courses.filter(item=> {
                return (item.lang === this.defaultLang) ? item : ''
            })
        },
        professorsTitle() {
            return this.heading[0].professors.filter(item=> {
                return (item.lang === this.defaultLang) ? item : ''
            })
        },
        total_professor(){
            return this.$store.getters.professors
        },
        online_courses(){
            return this.$store.getters.online_courses
        },
        happy_students(){
            return this.$store.getters.happy_students
        }
    },
   
    mounted(){
        window.addEventListener('scroll', this.counterStart);        
    },
    methods: {
        findLowest(){
            let lowestVal = 0
            if(this.total_professor < this.online_courses){
                if( this.total_professor < this.happy_students) {
                    lowestVal = this.total_professor
                } else {
                    lowestVal = this.happy_students
                }
            } else if( this.online_courses < this.happy_students) {
                lowestVal = this.online_courses
            } else {
                lowestVal = this.happy_students
            }
            if(lowestVal != 0 && lowestVal > 10){
                this.speed = lowestVal - 10;
            } else {
                this.speed = 0
            }
        },
        counterStart() {
            if(document.querySelector('.statistics-section') === null) 
                return;
            let oTop =  +document.querySelector('#counter').offsetTop + 350,
                a = 0;
			if (a == 0 && document.body.clientHeight < (window.pageYOffset+window.innerHeight+oTop)) {                
                document.querySelectorAll('.statistics-list .counter-value').forEach(item=>{
                    const updateCount = () => {
                        const target = +item.getAttribute('data-target');
                        const count = +item.innerText;

                        // Lower inc to slow and higher to slow
                        const inc = target / this.speed;

                        // Check if target is reached
                        if (count < target) {
                            // Add inc to count and output in counter
                            item.innerText = parseInt(count + inc);
                            // Call function every ms
                            setTimeout(updateCount, 400);
                        } else {
                            item.innerText = target;
                        }
                    };
                    updateCount();
                    a = 1;
                })
                
			}
        }
    },
    watch: {
        "$store.getters.professors"(val) {
            this.findLowest();
        }
    }
}
</script>