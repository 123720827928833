<template>
    <div class="section welcome_sec margin-top_50" v-if="pageData.page_heading">
        <div class="container">
            <div class="row">
                <div class="layout_padding_2" :class="(pageData.image_name) ? 'col-md-6' : 'col-md-12'">
                    <div class="full" v-if="pageData.content || pageData.page_heading">
                        <div class="heading_main text_align_left">
                            <h2>{{ pageData.page_heading }}</h2>
                        </div>
                        <div class="full" v-if="pageData.content">
                            <p class="MsoNormal" v-html="pageData.content"></p>
                        </div>
                        <!-- <div class="full">
                            <a class="hvr-radial-out button-theme" href="#">About more</a>
                        </div> -->
                    </div>
                </div>
                <div class="col-md-6" v-if="pageData.image_name">
                    <div class="full"> 
                        <img
                            :alt="pageData.page_heading"
                            data-sizes="auto"
                            :data-src="dir_path.original"
                            :data-srcset="dir_path.original+' '+'100w, '+
                            dir_path.original+' '+'600w, '+
                            dir_path.original+' '+'900w'" class="lazyload" 
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            pageData: [],
            dir_path: ''
        }
    },
    methods: {
        getPageData() {
            this.$http.get(`page/about`).then(res => {
                if(res.data.status === true) {
                    this.pageData = res.data.data
                    if(res.data.dir_path) {
                        this.dir_path = res.data.dir_path
                    }
                }
            })
            .catch(err => console.log(err))
        }
    },
    created: function() {
        this.getPageData()
    }
}
</script>

<style scoped>
.lazyload,
.lazyloading {
	opacity: 0;
}
.lazyloaded {
	opacity: 1;
	transition: opacity 300ms;
}
</style>