<template>
    <section class="categories section bg-light" v-if="categories.length > 0">
        <div class="container">
            <div class="row">
                <div class="col-lg-7 col-md-9 m-auto hchead text-center">
                    <div class=" heading_main text_align_center">
                        <h2 v-html="pageTitle[0].title"></h2>
                    </div>
                </div>
            </div>
            <div class="rs-courses-categories">
                <div class="row">
                    <div class="col-lg-3 col-md-6" v-for="(itm, index) of categories" :key="index">
                        <div class="courses-item">                        
                            <i :style="(itm.icon_color !== null) ? `color:${itm.icon_color}` : 'color:#fff'" :class="itm.icon"></i> 
                            <h4 class="courses-title"><router-link :to="`/category/${itm.id}/courses`">{{ itm.title }}</router-link></h4>
                            <span class="courses-amount">{{ itm.course_count }} {{ coursesTitle[0].title }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    data(){
        return {
           // languageId: 6,
            heading: [
                {lang: 'en', 'title': `Courses/Activities <span>Categories</span>`},
                {lang: 'ar', 'title': `موادّ/أنشطة<span/> فئات<span>`},
                {lang: 'fr', 'title': `Cours/Activités <span>Catégories</span>`}
            ],
            courses: [
                {lang: 'en', 'title': `COURSE(S)`},
                {lang: 'ar', 'title': `دورة`},
                {lang: 'fr', 'title': `COURS`}
            ],
            defaultLang: localStorage.getItem('_store_lang') || 'en'
            //categories: []
        }
    },    
    computed: {
        categories(){
            return this.$store.state.category.courseCategories
        },
        pageTitle() {
            return this.heading.filter(item=> {
                return (item.lang === this.defaultLang) ? item : ''
            })
        },
         coursesTitle() {
            return this.courses.filter(item=> {
                return (item.lang === this.defaultLang) ? item : ''
            })
        }
    },
    methods: {        
        /*courseCategories(){
            let params = {
                'paginate': 'all'
            }
            this.$store.dispatch('courseCategories', {params}).then(res=>{
                if(res.data.status === true)
                    this.categories = res.data.data
            })
            .catch(error => console.log('Error:' +error.response.data.error))
        }*/
    },
    created: function(){
        //this.courseCategories()
        
    }
}
</script>