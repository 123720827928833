<template>
    <div class="section layout_padding padding_bottom-0 latestcourse" v-if="courseData.length > 0">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <div class="full">
                        <div class="heading_main text_align_center">
                            <h2 v-html="pageTitle[0].title"></h2>
                        </div>
                    </div>
                </div>
            </div>
            <div class="carousel_wrap wow fadeInUp">
                <CourseCard :courses="courseData" :box="boxSize" />
            </div>	  
        </div>
    </div>
</template>

<script>
import CourseCard from '../components/Course/CourseCard'

export default {
    components: { CourseCard },
    data() {
        return {
            courseData: [],
            boxSize: 'col-sm-3',
            heading: [
                {lang: 'en', 'title': `<span>Latest </span>Courses`},
                {lang: 'ar', 'title': `أحدث الدورات`},
                {lang: 'fr', 'title': `<span>Derniers </span>cours`}
            ],
            defaultLang: localStorage.getItem('_store_lang') || 'en'
        }
    },
    computed: {
        pageTitle() {
            return this.heading.filter(item=> {
                return (item.lang === this.defaultLang) ? item : ''
            })
        }
    },
    methods: {
        getCourses() {
            let params = {
                limit : 8
            },
            dispatch = 'getLatestCourses'
            if(this.$store.getters.storeToken)
                dispatch = 'userCourses';

            this.$store.dispatch(dispatch, {params}).then(res=>{
                if(res.data.status === true) {
                    this.courseData = res.data.data;
                }
            })
            .catch(err=>{
                console.log(err.response.data.error)
            })
        }
    },
    created: function(){
        this.getCourses()
    }
}
</script>