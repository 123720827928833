<template>
    <fragment>    
        <div class="section layout_padding padding_bottom-0 section-light" v-if="brandData.length">
            <div class="container">
                <div class="carousel_wrap wow fadeInUp">
                    <div class="course-slider"> 
                        <carousel v-if="loaded" :loop="loop" :nav="nav" :responsive="responsive" :autoplay="autoplay" :margin="margin">
                            <div class="item" v-for="(item, indx) of brandData" :key="indx">
                                <img :src="dirPath.large+'/'+item.image_name" :alt="item.name">     
                            </div>                   
                        </carousel>
                    </div>
                </div>	  
            </div>
        </div> 
    </fragment>
</template>


<script>
import carousel from 'vue-owl-carousel'

export default {
    components: { carousel },
    data() {
        return {
            autoplay: true,
            nav: false,
            loop: true,
            margin: 15,
            responsive: {0:{items:1,nav:false},600:{items:2},641:{items:3},1024:{items:4},1500:{items:5}},
            loaded: false,            
            defaultLang: localStorage.getItem('_store_lang') || 'en',
            brandData: []
        }
    },
    methods: {
        getBrands() {
           this.$http.get('/brands').then(res=>{
				if(res.data.status === true){
					this.brandData = res.data.data
					if(res.data.path)
						this.dirPath = res.data.path
					this.loaded = true;
				}
			})
			.catch(err=>{
				console.log(err.response)
			})
        }
    },
    created: function(){
        this.getBrands()
    }
}
</script>